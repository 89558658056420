import { React, useState, useRef, useEffect } from 'react';
import { addGovernment, addGovernmentFTE, addGovernmentExpense } from '../../../service/admin';
import Swal from 'sweetalert2';

export default function FileUpload( { type } ) {

    const fileRef = useRef(null);

    const [file, setFile] = useState(null);
    const [fileError, setFileError] = useState('');

    useEffect(() => {
        fileRef.current.value = '';
        setFile(null);
        setFileError('');
    }, [type]);

    const handleFileChange = ( event ) => {
        const selectedFile = event.target.files[0];

        if (selectedFile && selectedFile.type === "text/csv") {
            setFile(selectedFile);
            setFileError('');
        } else {
            setFileError("Please select a valid CSV file.");
            setFile(null);
        }
    };

    const fileUploadSubmit = () => {

        if ( file ) {
            setFileError('');
            let formData = new FormData();

            if ( type === 'government' ) {
                formData.append(
                    'gov_file', file
                );

                addGovernment(formData).then(res => uploadFileResponseHandle(res));
            } else if ( type === 'governmentfte') {
                formData.append(
                    'gov_fileFte', file
                );

                addGovernmentFTE(formData).then(res => uploadFileResponseHandle(res));
            } else if ( type === 'governmentexpense' ) {
                formData.append(
                    'gov_fileExp', file
                );

                addGovernmentExpense(formData).then(res => uploadFileResponseHandle(res));
            } 
        } else {
            setFileError("Please select a file.");
        }
        

    }

    const uploadFileResponseHandle = ( res ) => {
        if ( res.data.code === 200 ) {
             Swal.fire({
                title: res.data.message,
                showCloseButton: true,
                confirmButtonText: "Ok",
                icon: 'success',
                allowOutsideClick: false
            });
        } else {
            Swal.fire({
                title: res.data.message,
                showCloseButton: true,
                confirmButtonText: "Ok",
                icon: 'warning',
                allowOutsideClick: false
            });
        }
        cancelHandle();
    }

    const cancelHandle = () => {
        fileRef.current.value = '';
        setFile(null);
        setFileError('');
    }

    return(
        <>
            <div className='fileWrapper'>
                    <div className="mb-3">
                        <label>File Upload (.csv only)<span className='required'> *</span></label>
                        <input
                            type="file"
                            className="form-control"
                            accept='.csv'
                            onChange={(e) => handleFileChange(e)}
                            ref={fileRef}
                        />
                        <p className='error'>{fileError}</p>
                    </div>
                    <div className='uploadCSV'>
                        <button onClick={() => fileUploadSubmit()} className='btn btn-primary'>Upload</button>
                        <button onClick={() => cancelHandle()} className='btn btn-secondary'>Cancel</button>
                    </div>
            </div>
            
        </>
    )
}