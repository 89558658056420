// Vendor Imports
import React from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import Swal from 'sweetalert2';

// Local Imports
import {
  removeAdminUser
} from "../../reducers/admin/actions";
import { logoutAdmin } from "../../service/admin";
import {
  SignOff
} from "../../reducers/client/actions";


class HeaderComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: '',
    };
  }

  getLocation = (loc) => {
    let currentLoc = this.props.location.pathname;
    return currentLoc === loc ? ' active' : '';
  }

  logout = () => {
    this.setState({ loading: 'loading' });
    logoutAdmin().then(res => {
      if (res.data.code === 200) {
        this.setState({
          loading: '',
        });
        this.props.dispatch(SignOff());
        this.props.dispatch(removeAdminUser());
        this.props.history.push("/admin");
      } else {
        this.setState({
          loading: '',
        });
        Swal.fire({
          text: res.data.message,
          showCloseButton: true,
          confirmButtonText: "Ok",
          icon: 'error',
          allowOutsideClick: false
        });
        return;
      }
    })
  }

  render() {
    return (
      <div>
        <div id="header" className="admin-navbar">
          <nav className="navbar navbar-expand-lg navbar-light bg-white ">
            <a className="navbar-brand" href="/">
              <img
                src="/images/logo/poweralmanac.svg"
                alt="Power Almanac"
                className="logo"
                width="132"
                height="63"
              />
            </a>
            <button
              className="navbar-toggler navbar-toggler-right"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
              role="navigation"
            >
              <ul className='nav navbar-nav'>
                <li className={"nav-item" + this.getLocation('/admin/dashboard')}>
                  <Link
                    className="nav-link btn my-2 my-sm-0 "
                    to="/admin/dashboard" >
                    Dashboard
                  </Link>
                </li>
                <li className={"nav-item" + this.getLocation('/admin/registered-accounts')}>
                  <Link
                    className="nav-link btn my-2 my-sm-0 "
                    to="/admin/registered-accounts" >
                    Registered Accounts
                  </Link>
                </li>
                <li className={"nav-item" + this.getLocation('/admin/paypal-transactions')}>
                  <Link
                    className="nav-link btn my-2 my-sm-0 "
                    to="/admin/paypal-transactions" >
                    PayPal Transactions
                  </Link>
                </li>
                <li className={"nav-item" + this.getLocation('/admin/view-billing-history')}>
                  <Link
                    className="nav-link btn my-2 my-sm-0"
                    to="/admin/view-billing-history" >
                    View Billing History
                  </Link>
                </li>
                <li className={"nav-item" + this.getLocation('/admin/active-acc-stats')}>
                  <Link
                    className="nav-link btn my-2 my-sm-0"
                    to="/admin/active-acc-stats" >
                    Active Account Stats
                  </Link>
                </li>
                <li className={"nav-item" + this.getLocation('/admin/over-all-stats')}>
                  <Link
                    className="nav-link btn my-2 my-sm-0"
                    to="/admin/over-all-stats" >
                    Overall Stats
                  </Link>
                </li>
                <li className={"nav-item" + this.getLocation('/admin/subscription')}>
                  <Link
                    className="nav-link btn my-2 my-sm-0"
                    to="/admin/subscription" >
                    Subscription
                  </Link>
                </li>
                {/*<li className={"nav-item" + this.getLocation('/admin/api_users')}>
                  <Link
                    className="nav-link btn my-2 my-sm-0"
                    to="/admin/api_users" >
                    API Users
                  </Link>
                </li>*/}
                <li className={"nav-item" + this.getLocation('/admin/add/government')}>
                  <Link
                    className="nav-link btn my-2 my-sm-0"
                    to="/admin/add/government" >
                    Add Government
                  </Link>
                </li>
                <li className={"nav-item" + this.getLocation('/admin/add/fte')}>
                  <Link
                    className="nav-link btn my-2 my-sm-0"
                    to="/admin/add/governmentfte" >
                    Add Fte
                  </Link>
                </li>
                <li className={"nav-item" + this.getLocation('/admin/add/expense')}>
                  <Link
                    className="nav-link btn my-2 my-sm-0"
                    to="/admin/add/governmentexpense" >
                    Add Expense
                  </Link>
                </li>
                
              </ul>
              <ul className="logout">
                <li onClick={this.logout}><button
                  className={"nav-link admin-logout-btn btn my-2 my-sm-0 " + this.state.loading}
                >
                  Logout <img
                    src="/images/log-out.png"
                    alt="Logout"
                  />
                </button></li>

              </ul>
            </div>
          </nav>
        </div>
      </div>
    );
  }
}

// Export
export default withRouter(connect()(HeaderComponent));
