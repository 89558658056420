import { React } from 'react';
import { Helmet } from 'react-helmet';
import FileUpload from './FileUpload';
import { useParams } from 'react-router-dom/cjs/react-router-dom';

export default function AddGovernment() {

    const params = useParams();
    console.log('params', params.type)

    return(
        <>
            <Helmet>
                <title> 
                    {
                        params.type === 'government' ?
                            'PA Admin - Add Government'
                        :
                            params.type === 'governmentfte' ?
                            'PA Admin - Add FTE'
                        :
                            params.type === 'governmentexpense' ?
                            'PA Admin - Add Expense'
                        :
                            <></>
                    }
                </title>
            </Helmet>
            <section>
                <div className="container admin-container">
                    <div className="row">
                        <div className="col-12">
                            <section className="admin-title admin-table d-flex mt-2">
                                <h1>
                                    {
                                        params.type === 'government' ?
                                            'Add Government'
                                        :
                                            params.type === 'governmentfte' ?
                                            'Add Government FTE'
                                        :
                                            params.type === 'governmentexpense' ?
                                            'Add Government Expense'
                                        :
                                            <></>
                                    }
                                </h1>
                                {/* <button type="button" className="btn btn-primary m-auto " onClick={this.handleMonthlyReportButton}>Monthly Report</button> */}
                            </section>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <FileUpload type={params.type}/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}