import React from 'react';
import { connect, useSelector } from 'react-redux';
import { renderCount, renderEmailPercent } from "../../../../utils";
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';

// Role based search stats
function ByRole(props) {
    let tamMode = useSelector(store => store.client.tamMode);

    const useStylesBootstrap = makeStyles((theme) => ({
        arrow: {
            color: '#000',
        },
        tooltip: {
            backgroundColor: '#000',
            fontSize: "1rem"
        },
    }));
    const classes = useStylesBootstrap();

    let Summary = 'JSON_SUMMARY' in props.searchResult ? JSON.parse(props.searchResult.JSON_SUMMARY) : {};
    let Officials = 'JSON_OFFICIALS' in props.searchResult ? JSON.parse(props.searchResult.JSON_OFFICIALS) : {};
    let Governments = 'JSON_GOVERNMENTS' in props.searchResult ? JSON.parse(props.searchResult.JSON_GOVERNMENTS) : {};
    // <sup> <span class='new-text-small '> NEW!</sup>
    let Roles = [ 
        { label: "Head of Corrections <sup> <span class='new-text-small '> NEW!</sup>", count: 0, name: "HeadofCorrections", emailCount: "HeadofCorrectionsEmail", govCount: "HeadofCorrectionsGov" },
        { label: "Head of Geographic  Information <br> System ", count: 0, name: "HeadofGeographicInformationSystem", emailCount: "HeadofGeographicInformationSystemEmail", govCount: "HeadofGeographicInformationSystemGov" },
        { label: "Head of Health Services", count: 0, name: "HeadofHealthServices", emailCount: "HeadofHealthServicesEmail", govCount: "HeadofHealthServicesGov" },
        { label: "Head of Facilities Management", count: 0, name: "HeadofFacilitiesManagement", emailCount: "HeadofFacilitiesManagementEmail", govCount: "HeadofFacilitiesManagementGov" },
        { label: "Head of Economic Development ", count: 0, name: "EconomicDevelopment", emailCount: "EconomicDevelopmentEmail", govCount: "EconomicDevelopmentGov" },
        { label: "Head of Planning/Zoning", count: 0, name: "Zoning", emailCount: "ZoningEmail", govCount: "ZoningGov" },
        { label: "Head of Parks Recreation and Wildlife ", count: 0, name: "ParksandRecreation", emailCount: "ParksandRecreationEmail", govCount: "ParksandRecreationGov" },
        { label: "Head of Water Supply ", count: 0, name: "HeadOfWaterSupply", emailCount: "HeadOfWaterSupplyEmail", govCount: "HeadOfWaterSupplyGov" },
        { label: "Head of IT", count: 0, name: "HeadofIT", emailCount: "HeadofITEmail", govCount: "HeadofITGov" },
        { label: "Head of Finance", count: 0, name: "HeadofFinanceBudgeting", emailCount: "HeadofFinanceBudgetingEmail", govCount: "HeadofFinanceBudgetingGov" },
        { label: "Head of Purchasing", count: 0, name: "HeadofPurchasingProcurement", emailCount: "HeadofPurchasingProcurementEmail", govCount: "HeadofPurchasingProcurementGov" },
        { label: "Head Clerk", count: 0, name: "Clerk", emailCount: "ClerkEmail", govCount: "ClerkGov" },
        { label: "Head of Public Works", count: 0, name: "HeadofPublicWorks", emailCount: "HeadofPublicWorksEmail", govCount: "HeadofPublicWorksGov" },
        { label: "Head of Law Enforcement <br/> <span class='text-small'>(e.g. the Police Chief, Sheriff)</span>", count: 0, name: "HeadofLawEnforcement", emailCount: "HeadofLawEnforcementEmail", govCount: "HeadofLawEnforcementGov" },
        { label: "Head of Fire Protection Services <br/> <span class='text-small'>(e.g. the Fire Chief)</span>", count: 0, name: "HeadofFireProtectionServices", emailCount: "HeadofFireProtectionServicesEmail", govCount: "HeadofFireProtectionServicesGov" },
        { label: "Head of Buildings", count: 0, name: "HeadBuildingOfficial", emailCount: "HeadBuildingOfficialEmail", govCount: "HeadBuildingOfficialGov" },
        { label: "Head of Communications", count: 0, name: "HeadofCommunications", emailCount: "HeadofCommunicationsEmail", govCount: "HeadofCommunicationsGov" },
        { label: "Head of HR", count: 0, name: "HeadofHR", emailCount: "HeadofHREmail", govCount: "HeadofHRGov" },
        
        { label: "Top Elected Official <br/> <span class='text-small'>(e.g. the Mayor)</span>", count: 0, name: "TopElectedOfficial", emailCount: "TopElectedOfficialEmail", govCount: "TopElectedOfficialGov" },
        { label: "Governing Board and Cabinet Members <br/> <span class='text-small'>(e.g. the Council Members)</span>", count: 0, name: "GoverningBoardMember", emailCount: "GoverningBoardMemberEmail", govCount: "GoverningBoardMemberGov" },
        { label: "Top Appointed Executive <br/><span class='text-small'>(e.g.the City Manager)</span>", count: 0, name: "TopAppointedOfficial", emailCount: "TopAppointedOfficialEmail", govCount: "TopAppointedOfficialGov" },
        { label: "Deputy Top Appointed Executive <br> <span class='text-small'>(e.g. the Assistant City Manager)</span>", count: 0, name: "DeputyTopAppointedExecutive", emailCount: "DeputyTopAppointedExecutiveEmail", govCount: "DeputyTopAppointedExecutiveGov" },
    ];

    return (
        <>
            <tbody id="searchResultsPosition">
                {
                    Roles.map((role, index) => {

                        return (
                            <tr key={index}>
                                {/*<td>
                                    {
                                        props.plan.Sub_Level !== "10" && role.name === 'HeadofCorrections' ?

                                            <Tooltip classes={classes} placement="top" arrow title="Power Max users have Beta Access to this new role. All other users will have access beginning on May 02." >
                                                <span>
                                                <span dangerouslySetInnerHTML={{ __html: role.label }} />{" "}
                                                    <span className='new-text-small text-small'>
                                                        <br />Beta for Power Max Users Only


                                                    </span>

                                                </span>
                                            </Tooltip>
                                            :
                                            props.plan.Sub_Level === "10" && role.name === 'HeadofCorrections' ?
                                                <Tooltip classes={classes} placement="top" arrow title="Power Max users have Beta Access to this new role. All other users will have access beginning on May 02." >
                                                    <span >
                                                    <span dangerouslySetInnerHTML={{ __html: role.label }} />{" "}
                                                       
                                                        <span className='new-text-small text-small'>
                                                            <br />Beta for Power Max Users Only


                                                        </span>

                                                    </span>
                                                </Tooltip>
                                                :
                                                <span dangerouslySetInnerHTML={{ __html: role.label }} />

                                    }

                                </td>*/}
                                <td>
                                    <span dangerouslySetInnerHTML={{ __html: role.label }} />
                                </td>
                                <td>
                                    <span className="setToZero">{renderCount(Officials[role.name])} </span>
                                </td>
                                {
                                    tamMode ?
                                        <>
                                            <td>
                                                <span className="setToZero">{renderCount(Governments[role.govCount])}</span>
                                            </td>
                                            <td> {renderEmailPercent(Summary.TamGovs, Governments[role.govCount])}%</td>
                                        </> :
                                        <>
                                            <td>
                                                <span className="setToZero">{renderCount(Officials[role.emailCount])}</span>
                                            </td>
                                            <td> {renderEmailPercent(Officials[role.name], Officials[role.emailCount])}%</td>
                                        </>
                                }
                            </tr>
                        );
                    })
                }


            </tbody>

        </>

    )
}

function mapStateToProps(store, props) {
    return {
        plan: store.client.plan ? store.client.plan : [],
        searchResult: store.client.searchResult ? store.client.searchResult : []
    }
}

export default connect(mapStateToProps, {})(ByRole);
